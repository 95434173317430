export const pl = {
  message: {
    demo: {
      company: 'Firma',
      localization: 'Lokalizacja',
      createAccount: 'Przetestuj demo',
      thankYou: 'Dziękujemy!',
      successMessage:
        'W ciągu 5 minut na podany adres e-mail otrzymasz wiadomość z dalszymi instrukcjami. W międzyczasie zapraszamy do zapoznania się z naszą bazą wiedzy:',
      errorMessage: 'Coś poszło nie tak, spróbuj ponownie za kilka minut.',
      nip: 'Numer NIP',
      company_email: 'Adres e-mail',
      menu_url: 'Link do strony restauracji na pyszne.pl',
      name: 'Nazwa lokalizacji',
      city: 'Miasto',
      address: 'Adres',
      phone: 'Numer telefonu',
      localization_email: 'Adres e-mail',
      fineprint:
        'Administratorem danych osobowych jest PapuKurier sp. z o.o. z siedzibą w Poznaniu, przy ul. Sowińskiego 18A, z którym możesz skontaktować się pod adresem: kontakt@papu.io. Twoje dane osobowe będę przetwarzane w celu nawiązania kontaktu z Tobą w szczególności w celu przedstawienia Ci naszej oferty handlowej. W związku z przetwarzaniem danych osobowych przysługuje Ci prawo dostępu do danych, ich sprostowania, usunięcia, ograniczenia przetwarzania, przenoszenia, wniesienia sprzeciwu oraz złożenia skargi do organu nadzorczego. Szczegółowe informacje dostępne są w <a href="https://papu.io/polityka-prywatnosci/" target="_blank">Polityce prywatności</a>.',
      phoneCalls: 'Wyrażam zgodę na kontakt telefoniczny i poprzez e-mail.',
      pl: 'Polska',
      uk: 'Wielka Brytania',
      de: 'Niemcy',
      country: 'Kraj',
      street: 'Ulica',
      house: 'Nr domu',
      kitchenType: 'Typ kuchni',
      kitchenTypeOptions: {
        1: 'Amerykańska',
        2: 'Bistro',
        3: 'Burgery',
        4: 'Indyjska',
        5: 'Kebab',
        6: 'Mix house',
        7: 'Pizza & Kebab',
        8: 'Pizzeria',
        9: 'Ramen',
        10: 'Steak house',
        11: 'Sushi',
        12: 'Tajska',
        13: 'Turkish',
        14: 'Vege',
        15: 'Włoska',
      },
      logo: 'Logo',
    },
    common: {
      courier: 'Kurier',
      reset: 'Resetuj',
      pickTime: 'Wybierz czas',
      weekTimeTable: {
        bothTimeFieldsMustBeFilled: 'Oba pola godzin dla danego dnia muszą zostać uzupełnione.',
      },
      cancel: 'Anuluj',
      edit: 'Edytuj',
      save: 'Zapisz',
      delete: 'Usuń',
      savingWait: 'Trwa zapisywanie. Proszę czekać...',
      default: 'Domyślny',
      continue: 'Kontynuuj',
      selectFromList: 'Wybierz z listy',
      other: 'Inny',
      confirm: 'Potwierdź',
      card: 'Karta',
      cash: 'Gotówka',
      paid: 'Zapłacone',
      end: 'Zakończ',
      bill: 'Paragon',
      all: 'Całość',
      printAgain: 'Drukuj ponownie',
      enterCash: 'Wprowadź gotówkę',
      price: 'Cena',
      rest: 'Reszta',
      cashCalculator: 'Kalkulator gotówki',
      order: 'Zamówienie',
      move: 'Przenieś',
      paymentMethod: 'Metoda płatności',
      or: 'Lub',
      anErrorOccured: 'Wystąpił błąd',
      discardChangesQuestion: 'Czy chcesz porzucić niezapisane zmiany?',
      discardChanges: 'Porzuć zmiany',
      stayOnPage: 'Zostań na stronie',
      back: 'Powrót do poprzedniej strony',
      none: 'Brak',
      noData: 'Brak danych',
      close: 'Zamknij',
      menu: 'Menu',
      saving: 'Trwa zapisywanie',
      knowMore: 'Dowiedz się więcej.',
      source: 'Źródło',
      sourceMenu: 'Źródło menu',
      name: 'Nazwa',
      size: 'Rozmiar',
      group: 'Grupa',
      category: 'Kategoria',
      errorOccured: 'Wystąpił błąd',
      color: 'Kolor',
      user: 'Użytkownik',
      menuDefault: 'Menu domyślne',
      choose: 'Wybierz',
    },
    auth: {
      signIn: 'Logowanie',
      signUp: 'Rejestracja',
      email: 'Adres email',
      password: 'Hasło',
      confirmPassword: 'Potwierdź hasło',
      signInButton: 'Zaloguj się',
      signUpButton: 'Zarejestruj się',
      username: 'Nazwa użytkownika',
      courierCanNotLogin: 'Kurierzy nie mają dostępu do panelu PapuPOS!',
      appDownload: 'Pobierz aplikację dla kierowców Papu.io',
      loggedOutInvalidToken:
        'Zostałeś wylogowany (prawdopodobnie na innym urządzeniu został kliknięty przycisk wyloguj).',
      forgotPassword: 'Zapomniałeś hasła?',
      passwordReset: 'Zresetuj hasło',
      send: 'Wyślij',
      setNewPassword: 'Ustaw nowe hasło',
      resetLinkExpired: 'Link do resetowania hasła wygasł. Możesz ponowne zresetować hasło.',
      canSignIn: 'Możesz się teraz zalogować.',
      selectLocalization: 'Wybierz lokalizację',
      loadingMenu: 'Pobieranie menu',
    },
    employeeForm: {
      employeeType: 'Typ pracownika',
      employeeTypes: {
        waiter: 'Kelner',
        courier: 'Dostawca',
        manager: 'Menedżer',
        owner: 'Właściciel',
        cook: 'Kucharz',
      },
      employeeName: 'Imię i nazwisko',
      phoneNumber: 'Numer telefonu',
      email: 'Adres email',
      password: 'Hasło',
      confirmPassword: 'Potwierdź hasło',
      isActive: 'Aktywny',
      addUserButton: 'Dodaj użytkownika',
      saveChangesButton: 'Zapisz zmiany',
      courierType: 'Typ dostawcy',
      courierTypes: {
        core: 'Podstawowy',
        uber: 'Giełda',
      },
      courierTransportMethod: 'Środek transportu',
      courierTransportMethods: {
        car: 'Samochód',
        motorbike: 'Skuter',
        bike: 'Rower',
      },
      sendApp: 'Wyślij aplikację',
      appSent: 'Aplikacja została wysłana',
      appInfo:
        'W celu pobrania aplikacji dla kierowców, wejdź na swoim telefonie na stronę główną rest.papu.io ' +
        'i kliknij w przycisk pobrania aplikacji ze sklepu AppStore/GooglePlay lub wyszukaj "papu.io" w wybranym sklepie. ' +
        'Dostawca zaloguje się do aplikacji podanym w tym formularzu adresem email i hasłem.',
      salaryPerKm: 'Stawka za km',
      salaryPerHour: 'Stawka za godzinę',
      salaryPerDelivery: 'Stawka za dostawę',
      salaryPerZoneDelivery: 'Płatność za dostawę w strefie',
      verifyEmailMessage:
        'Na podany adres e-mail został wysłany link weryfikujący. Kliknij w niego i potwierdź adres e-mail w aplikacji ADMIN PAPU.io.',
      updateEmployeeSuccess: 'Użytkownik został zapisany.',
    },
    employeeList: {
      showOnlyActive: 'Pokaż tylko aktywnych pracowników',
      showAllEmployees: 'Pokaż wszystkich pracowników',
      name: 'Imię i nazwisko',
      username: 'Nazwa użytkownika',
      phone: 'Telefon',
      active: 'Aktywny',
      type: 'Typ',
      edit: 'Edytuj',
      yes: 'Tak',
      no: 'Nie',
      courier: 'Dostawca',
      waiter: 'Kelner',
      manager: 'Menedżer',
      owner: 'Właściciel',
      loggedIn: 'Zalogowany',
      remove: 'Usuń',
      removeEmployee: 'Usuń pracownika',
      areYouSure: 'Czy jesteś pewny, że chcesz usunąć pracownika',
    },
    orderDetails: {
      orderNumber: 'Numer',
      orderSource: 'Źródło',
      orderStatus: 'Status',
      customerDetails: 'Informacje o kliencie',
      customerData: 'Dane klienta',
      orderContent: 'Zawartość zamówienia',
      edit: 'Edytuj',
      close: 'Zamknij',
      changeOrderLocalization: 'Zmień lokalizację zamówienia',
      changeOrderLocalizationInfo: 'Przekaż zamówienie do innej restauracji',

      changeOrderLocalizationDescription:
        'Zamówienie zniknie z aktualnej listy zamówień i będzie widoczne ' + 'tylko dla wybranej lokalizacji',
      transferedFrom: 'Zamówienie przesłane z',
      transferOrder: 'Prześlij zamówienie',
      finishOrderButton: 'Zakończ',
      finishOrder: 'Zakończ zamówienie',
      printingError: 'Wystapił błąd',
      printingErrorTryAgain: 'Spróbuj ponownie',
      printOrderConfirmation: {
        btn: 'Bon',
        success: 'Drukowanie potwierdzenia zamówienia w toku',
      },
      printBill: 'Paragon',
      closeOrderOnPrintBill: 'Automatycznie zamknij zamówienie',
      finishOrderChoices: {
        canceled: 'Anulowane',
        delivered: 'Dostarczone',
        notDelivered: 'Niedostarczone',
        pickedUp: 'Odebrane',
        serviceCharge: 'Opłata za serwis',
        gloVoServiceCharge: 'Opłata serwisowa Glovo',
        tip: 'Napiwek',
      },
      accept: 'Akceptuj',
      acceptFor: 'Akceptuj dla',
      acceptInfo: 'To zamówienie będzie możliwe do zrealizowania przez Twoją restaurację',
      decline: 'Odrzuć',
      declineTransferInfo: 'To zamówienie wróci do restauracji {oldLocalizationName}',
      changePaymentMethod: 'Zmień sposób płatności',
      changeFinishOrderButton: 'Zmień',
      selectPromisedTime: 'Zaznacz czas obiecany klientowi',
      changeOrAcceptPromisedTime: 'Zmień lub zaakceptuj czas obiecany klientowi',
      billAlreadyPrinted: 'Ten paragon był już wydrukowany',
      printInvoice: 'FV',
      restoreOrder: 'Przywróć',
      orderRestored: 'Zamówienie zostało przywrócone',
      printInvoiceResult: {
        noVatrate: 'Nieustawione stawki VAT',
        noOrder: 'Brak takiego zamówienia',
        alreadyExists: 'Faktura już istnieje',
        unknownError: 'Nieznany błąd',
        localizationMismatch: 'Niespójne lokalizacje',
        successPrintInvoice: 'Drukowanie faktury w toku',
        successSendInvoiceByEmail: 'Faktura wysłana na e-mail',
      },
      paymentTypeNotSet: 'Forma płatności nieustawiona',
      paymentTypeUpdateError: 'Wystąpił problem ze zmianą płatności',
      tableNumberUpdateError: 'Wystąpił problem ze zmianą numeru stolika',

      paymentTypeChanged: 'Sposob płatności został zmieniony',
      tableNumberChanged: 'Numer stolika został zmieniony',
      orderNotAccepted:
        'By zaakceptować to zamówienie: kliknij poniżej przycisk Akceptuj (lub określ obiecany czas), ' +
        'a następnie kliknij przycisk Ok. Zamówienie pojawi się na liście zamówień. ' +
        'Przez moment (do 30 sekund) zamówienie nie będzie miało uzupełnionych danych, ' +
        'ale nie przejmuj się, uzupełnią się automatycznie.',
      table: {
        nameTable: 'Stolik',
        missingTable: 'Brak stolika',
      },
      today: 'Dzisiaj',
    },
    mealDetails: {
      chooseSize: 'Wybierz rozmiar',
      chooseAdditives: 'Wybierz dodatki',
      discount: 'Zniżka',
      valueDiscount: 'Zniżka kwotowa',
      promotionalPrice: 'Cena promocyjna',
      quantity: 'Ilość',
      addComment: 'Dodaj komentarz',
      splitBill: 'Dzielony rachunek',
      main: 'Główny',
      billNo: 'Rachunek nr',
      createNew: 'Utwórz nowy',
      totalPrice: 'Łączna cena',
      add: 'Dodaj',
      save: 'Zapisz',
      delete: 'Usuń',
      price: 'Cena',
      changeHalfPizza: 'Zmień pizzę',
      changeMeal: 'Zmień danie',
      finalView: 'Podsumowanie',
      discountDisabled: 'Niedostępna.',
      discountDisabledInfo: 'Zniżka na danie jest niedostępna, ponieważ nadano już rabat na zamówienie.',
      selectionRequired: 'Zaznaczenie wymagane',
      mealRemovedFromMenu:
        'Danie zostało usunięte z menu. Jedyne co możesz zrobić z tą pozycją to usunąć ją z zamówienia.',
      left: 'Lewa',
      right: 'Prawa',
      chooseMeal: 'Wybierz danie',
      none: 'Brak',
      submealsNotFilled: 'Uzupełnij wszystkie elementy zestawu',
    },
    mealsMenu: {
      search: 'Szukaj',
      mealsList: 'Lista dań',
    },
    categories: {
      mealSets: 'ZESTAWY',
    },
    mealsModal: {
      title: 'Wybierz pizzę',
    },
    multiChoiceEntryModal: {
      search: 'Szukaj',
      ingredients: 'Składniki',
      selectedAdditives: 'Wybrane dodatki',
      paidAdditives: 'Płatne dodatki',
      freeAdditives: 'Darmowe dodatki',
      save: 'Zapisz',
      additives: 'Dodatki',
      noIngredients: 'Brak składników',
      noAdditives: 'Brak dodatków',
      noAdditivesToChoose: 'Brak dodatków do wyboru',
      noIngredientsToChoose: 'Brak skladników do wyboru',
      deletedIngredients: 'Usunięte składniki',
      removedMultiChoiceEntries: 'Usunięte dodatki',
    },
    cart: {
      title: 'Zamówienie na miejscu',
      name: 'Nazwa',
      quantity: 'Ilość',
      unitPrice: 'Cena jednostkowa',
      discountLabel: 'Zniżka',
      discountValue: 'Zniżka kwotowa',
      cart_percent_discount: 'Zniżka % na zamówieniu',
      cart_promotional_price: 'Cena promocyjna zamówienia',
      percent: 'Procent',
      value: 'Kwota',
      promotionalPrice: 'Cena promocyjna',
      freeDelivery: 'Darmowa dostawa',
      discount: 'Zniżka na daniu',
      sum: 'Suma',
      edit: 'Edytuj',
      totalPrice: 'Łączna cena',
      totalBillPrice: 'Paragon wydrukowany na kwotę',
      deliveryPrice: 'Cena dostawy',
      deliveryPriceIncluded: 'Dostawa',
      comment: 'Komentarz',
      vat: 'VAT',
      vatExempt: 'Zwolniony',
      none: 'Brak',
      discount_all: 'Zniżki',
      deliveryArea: 'Strefa dostawy',
      actualMeal: 'Aktualne danie',
      discountDisabled: 'Zniżka niedostępna.',
      discountDisabledInfo: 'Zniżka na zamówienie jest niedostępna, ponieważ nadano już rabat na przynajmniej 1 danie.',
      discountCode: 'Kod promocyjny',
      applyCode: 'Użyj kodu',
      tableNumber: 'Numer stolika',
      differentTableNumber: 'Wprowadź inny numer',
      printBill: 'Drukuj paragon',
      closeOrder: 'Zamknij zamówienie',
      paymentMethod: 'Forma płatności',
      releaseType: 'Typ wydania',
      insertCash: 'Wprowadź gotówkę',
      price: 'Cena',
      change: 'Reszta',
      noMeals: 'Brak dań',
      mainBill: 'Rachunek główny',
      tip: 'Napiwek',
      packagePriceIncluded: 'Opakowanie',
      packagePrice: 'Cena opakowania',
      additionalFees: 'Dodatkowe opłaty',
      orderContent: 'Zawartość zamówienia',
      addMeal: 'Dodaj pozycję',
    },
    orderFormFull: {
      customerDetails: 'Informacje o kliencie',
      phoneNumber: 'Numer telefonu',
      address: 'Adres',
      name: 'Imię i nazwisko',
      customerTaxID: 'NIP',
      pickupType: 'Typ odbioru',
      pickupTypes: {
        onSite: 'Na miejscu',
        takeaway: 'Na wynos',
        delivery: 'Dostawa',
      },
      deliveryTypes: {
        asap: 'Jak najszybciej',
        onTime: 'Na wybraną godzinę',
      },
      paymentType: 'Typ płatności',
      paymentTypes: {
        cash: 'Gotówka',
        card: 'Karta',
        paid: 'Zapłacone',
      },
      promisedTime: 'Obiecany czas',
      placeOrder: 'Złóż zamówienie',
      updateOrder: 'Aktualizuj zamówienie',
      deliveryType: 'Typ dostawy',
      emptyCart: 'Koszyk jest pusty, proszę dodać dania.',
      minValue: 'Do minimalnej kwoty zamówienia brakuje',
      minValueFrom: 'Do darmowej dostawy brakuje',
      noDeliveryAreasConfigured:
        'Brak zdefiniowanych stref. Zaloguj się na konto managera i w ustawieniach zdefiniuj strefy i kwoty za dostawę.',
      noDeliveryArea: 'Podaj adres, aby znaleźć strefę dostawy.',
      outOfDeliveryArea: 'Adres poza strefą dostawy.',
      orderSource: 'Źródło zamówienia',
      choose: 'Wybierz',
      changeOrderSource: 'Zmień źródło zamówienia',
      printBill: 'Drukuj paragon',
      addressComment: 'Komentarz do adresu',
      removeAddressConfirm: 'Czy na pewno chcesz usunąć ten adres klienta?',
      yes: 'Tak',
      no: 'Nie',
      deliveryPriceFromArea: 'Ustaw cenę dostawy',
      useNewAreaDeliveryPrice: 'Czy chcesz użyć ceny dostawy {calculatedDeliveryPrice} z nowej strefy?',
      deliveryAreaChanged: 'Strefa dostawy została zmieniona',
      deliveryPriceChangeDueToCart:
        'Dla tej wartości zamówienia i adresu, kwota dostawy wynikająca ze zdefiniowanych ' +
        'stref wynosi {deliveryPrice}. Czy ustawić kwotę dostawy na {deliveryPrice} w tym zamówieniu?',
      payment: 'Płatność',
      newOrder: 'Nowe zamówienie',
    },
    orderFormShort: {
      number: 'Numer',
      customer: 'Klient',
      address: 'Adres',
      changeDetails: 'Dane klienta',
      addOrderOnSite: 'Dodaj zamówienie na miejscu',
      order: 'Zamówienie',
      new: 'Nowe',
    },
    promisedTime: {
      ownPromisedTime: 'Własny',
      recommendedByAi:
        'Nasza sztuczna inteligencja przewiduje następujacy czas dostawy. ' +
        'Jeśli go nie akceptujesz, wybierz ręcznie inny czas.',
      futurePromisedTimeInfo:
        'Zamówienie z obiecanym czasem ponad 24 godzin od obecnego momentu nie będzie ' +
        'widoczne na liście aktualnych zamówień',
      predictingPromisedTime: 'Przewidywanie czasu dostawy',
    },
    futureDateConfirmModal: {
      chosenDateBigger: 'Wybrany czas jest większy niż 12 godzin od aktualnego momentu',
      chosenDateBiggerInfo:
        'Ustawiasz {dateType} na dzień {dateTimeDisplay} - dzieli nas od tego momentu {hoursToDate} ' +
        'godzin. Czy na pewno?',
      promisedTime: 'obiecany czas',
      shiftFinishedAt: 'datę zakończenia zmiany',
    },
    assignCourier: {
      assignCourierToOrder: 'Przydziel dostawcę do zamówienia',
      assignCourierToOrders: 'Przydziel dostawcę do zamówień',
      none: 'Usuń dostawcę',
      emptyCouriersList: 'Brak zalogowanych dostawców.',
    },
    ordersMap: {
      clearSelectedOrders: 'Odznacz wszystkie zamówienia',
      selectOrders: 'Zaznacz wszystkie zamówienia',
      assignCourierToOrders: 'Przydziel dostawcę do zamówień',
      regeocodeOrders: 'Ponów geolokalizację',
    },
    ordersList: {
      search: 'Szukaj',
      searchInfo: 'Szukaj - wpisz nazwę dania, nr zamówienia, kwotę, adres, nr. tel, imię lub nazwisko klienta',
      unaccepted: 'Niezaakceptowane',
      accepted: 'Zaakceptowane',
      todo: 'Do zrobienia',
      deliveringIn: 'W trakcie dostawy',
      futureOrders: 'Na przyszłość',
      remainingPromisedTime: 'Pozostały czas',
      address: 'Adres',
      payment: 'Płatność',
      meals: 'Potrawy',
      courier: 'Dostawca',
      status: 'Status',
      source: 'Źródła',
      assignCourier: 'Przydziel dostawcę',
      cantAssignCourierNotAllMealsReady: 'Nie możesz przypisać dostawcy, jeśli nie wszystkie dania są gotowe',
      price: 'Cena',
      priceTooltip: 'Kwota produktów + Kwota dostawy = Kwota zamówienia',
      onSite: 'Na miejscu',
      takeaway: 'Na wynos',
      delivery: 'Dostawa',
      filterByEmployee: 'Pracownicy',
      filterByPaymentMethod: 'Formy płatności',
      createdAt: 'Data utworzenia',
      finishedAt: 'Data zakończenia',
      promisedTime: 'Data obiecana',
      orderStatus: 'Status zamówienia',
      statusFinished: 'Zakończone',
      statusCurrent: 'Trwające',
      none: 'Brak',
      finishedStatus: 'Typ zakończenia',
      all: 'Wszystkie',
      noAddress: 'Adres nierozpoznany - brak zamówienia na mapie',
      restaurants: 'Restauracje',
      restaurant: 'Restauracja',
      total: 'Łącznie',
      for: 'zamówień za',
      discountsFor: 'Rabaty na',
      billValueHigherInOrders: 'Kwoty paragonów wyższe od wartości zamówień o',
      noOrders: 'Brak zamówień dla wybranego kryterium filtrowania.',
      discountFilter: 'Tylko z rabatem',
      billValueHigherFilter: 'Tylko z kwotą paragonu wyższą niż kwota zamówienia',

      discount: 'Rabat',
      uberDiscountFilter: 'Tylko ze zniżką z UberEats (zniżki liczone są od daty 06.05.2021)',
      uberDiscountFor: 'Łączne zniżki z UberEats',
      onTime: 'Zamówienie na konkretny czas.',
      printed: 'Paragon został wydrukowany.',
      orderBillValuesMismatch: 'Wartość tego zamówienia jest mniejsza od wartości paragonu.',

      customerPhone: 'Numer kontaktowy',
      pastDaysOrders:
        'Archiwum zostało ograniczone do {past_days_orders} dnia. | Archiwum zostało ograniczone do {past_days_orders} dni.',
      pastDaysOrdersInfo:
        'Możesz nie widzieć wszystkich zamówień, ponieważ właściciel włączył ograniczenie związane z ich wyświetlaniem.',
      filterThroughDateType: 'Filtrowanie wg',
      browserPreventsSoundNotifications:
        'Przeglądarka uniemożliwiła automatyczne odtwarzanie multimediów. Kliknij "ODTWARZAJ", aby uruchomić multimedia.',
      browserPreventsSoundNotificationsActionText: 'ODTWARZAJ',
      filterByIsPrinted: 'Paragon',
      filterByHasInvoice: 'Faktura',
      isTransferred: 'Zamówienie przesłane',
      yes: 'Tak',
      no: 'Nie',
      isPrinted: 'Wydrukowano',
      isNotPrinted: 'Niewydrukowano',
      tipsFilter: 'Tylko z napiwkami',
      tips: 'Napiwki',
      tipsFor: 'Napiwki na',
      splittedBills: 'Rachunki podzielone',
      filters: 'Filtry',
      showFilters: 'Pokaż filtry',
      hideFilters: 'Ukryj filtry',
    },
    summary: {
      filterFromDate: 'Filtruj od',
      filterToDate: 'Filtruj do',
      filterByEmployee: 'Filtruj pracownika',
      name: 'Imię i nazwisko',
      role: 'Rola',
      paidByCash: 'Płatne gotówką',
      paidByCard: 'Płatne kartą',
      alreadyPaid: 'Zapłacone',
      unrealized: 'Niezrealizowane',
      realized: 'Zrealizowane',
      ordersNumber: 'Wszystkie',
      discounts: 'Rabaty',
      deliveries: 'Za dostawy',
      total: 'Razem',
      inProgress: 'W trakcie',
      uberDiscount: 'Zniżki z UberEats',
      uberDiscountTooltip:
        'Tę kwotę UberEats powinien zwrócić do restauracji (dotyczy zamówień o każdym statusie w Papu)',
      uberDiscountStartDateWarning: 'Zniżki nadawane przez UberEats liczone są od daty 06.05.2021',
      tips: 'Napiwki',
      canceled: 'Anulowane',
      billValueTooHigh: 'Pewne zamówienia mają niższą kwotę niż na wydrukowanych paragonach.',
      billValueTooHighOrdersBaseLink: 'Zobacz je',
      info: {
        summaryChanges:
          'Szanowni Klienci,\n W związku w wprowadzeniem możliwości dzielenia rachunków zmienia się trochę sposób rozliczenia z pracownikami. W celu stwierdzenia, ile dany pracownik pobrał gotówki, ile płatności kartą, a ile płatności online (zapłacone) musimy bazować na rachunkach, bo teraz jedno zamówienie może mieć np. rachunek płatny kartą i rachunek płatny gotówką.',
        billsTableTip:
          'W tabeli poniżej są rachunki z podziałem na formy płatności w kolumnach, przypisane do pracowników (w wierszach). Stąd proszę brać informację, ile pracownik przyjął płatności kartą, ile gotówką, a ile online (zapłacone).',
        ordersTableTip:
          'W tej tabeli jedyna zmiana jaka zaszła to dodaliśmy kolumnę "z podziałem płatności", która informuje o tym, ile było zamówień, za które płatność przyjęta została w różnych formach.',
      },
      orders: 'Zamówienia',
      bills: 'Rachunki',
      withSplitPayments: 'Z podziałem płatności',
      realizedOrders: 'Zrealizowane zamówienia',
      realizedBills: 'Zrealizowane rachunki',
    },
    settings: {
      payments: 'Płatności',
      settings: 'Zarządzanie',
      management: 'Zarządzanie',
      addEmployee: 'Dodaj pracownika',
      employeesList: 'Lista pracowników',
      orderBase: 'Baza zamówień',
      ordersSummary: 'Podsumowanie zamówień',
      subscriptions: 'Pakiety',
      salaries: 'Wynagrodzenia',
      payrollList: 'Lista wynagrodzeń',
      manHours: 'Roboczogodziny',
      penalties: 'Potrącenia',
      bonuses: 'Premie',
      advances: 'Zaliczki',
      kilometrage: 'Kilometrówka',
      restaurantCash: 'Środki restauracji',
      daySummary: 'Rozliczenie dnia',
      currentShifts: 'Bieżące zmiany',
      shifts: 'Zmiany',
      shiftsList: 'Lista zmian',
      addEditEmployee: 'Dodaj lub edytuj pracownika',
      regions: 'Strefy dostaw',
      currentBillingPeriods: 'Bieżące okresy rozliczeniowe',
      billingPeriods: 'Lista okresów rozliczeniowych',
      billings: 'Rozliczenia',
      employees: 'Pracownicy',
      orders: 'Zamówienia',
      billingPeriod: 'Okres rozliczeniowy',
      integrations: 'Integracje',
      kdsSettings: 'Ustawienia KDS',
      editWorkstation: 'Dodaj lub edytuj stanowisko',
      goToAdmin: 'Przejdź do admin.papu.io',
      actions: 'Akcje',
      openDrawer: 'Otwórz szufladę',
      openingDrawer: 'Otwieram szufladę...',
    },
    receiptPrintersSettings: {
      name: 'Ustawienia boniarek',
      singlePrinterView: 'Zarządzaj boniarkami',
      printerName: 'Nazwa boniarki',
      edit: 'Edytuj',
      free: 'Zwolnij',
      assign: 'Przypisz do boniarki',
      delete: 'Usuń',
      templates: 'Szablony',
      freeTemplates: 'Wolne szablony',
      save: 'Zapisz',
      create: 'Utwórz',
      printAllMeals: 'Drukuj wszystkie dania',
      printRemainingMeals: 'Drukuj dania niedopasowane przez inne szablony',
      printWhitelistedMeals: 'Drukuj dania tylko z listy',
      templateName: 'Nazwa szablonu',
      no_of_copies: 'Ilość kopii każdego wydrukowanego bonu',
      templateType: 'Typ szablonu',
      whitelist:
        'Lista fraz kluczowych, które zawierają dania drukowane przez szablon. Poszczególne frazy oddzielone enterem.',
      deletePrinterModalTitle:
        'Czy na pewno chcesz usunąć drukarkę? Wszystkie szablony przypisane do niej zostaną zwolnione.',
      cancel: 'Anuluj',
      saved: 'Zapisano',
      newPrinter: 'Dodaj nową boniarkę',
      newPrinterDetails: 'Podaj szczegóły dot. nowej boniarki',
      newTemplate: 'Dodaj nowy szablon',
      template: 'Szablon',
      ownReceiptDesign: 'Własny wygląd (aby dodać skontaktuj się z papu.io)',
      receiptDesign: 'Wygląd bonu',
      expand: 'Rozwiń',
      shrink: 'Zwiń',
      printerInternalId: 'Nazwa boniarki w systemie Windows',
      newPrinterNameTooltipText: 'Nazwa pod jaką boniarka będzie widoczna w naszym systemie. Np. "Kuchnia", "Grill".',
      newPrinterInternalIdTooltipText:
        'Nazwa pod jaką widnieje dana boniarka w Państwa systemie Windows. Aby się do niej dostać należy wejść w Panel Starowania, następnie Sprzęt i dźwięk, następnie Urządzenia i drukarki. Na koniec wystarczy przepisać nazwę drukarki, którą chcą Państwo dodać do naszego systemu.',
      assignedTemplates: 'Przypisane szablony',
      howItWorksCollapsible: 'Jak to działa?',
      howItWorksCollapsibleDescription:
        'Szablony decydują o tym jakie dania pojawią się na bonie, ile kopii bonu się wydrukuje, z której boniarki bon wyjdzie a także jak będzie dany bon wyglądał.\n"Przypisane szablony" to zbiór szablonów przypisanych do danej boniarki.\n"Wolne szablony" to zbiór szablonów, które nie są przypisane do żadnej boniarki. Jeśli szablon jest wolny nie ma on wpływu na druk bonów.\nUWAGA - jeśli do drukarki nie jest przypisany ��aden szablon, z drukarki nie będą wychodzić żadne bony.',
      exampleCollapsible: 'Przykład działania',
      exampleCollapsibleDescription:
        '1. Do boniarki "Kuchnia" przypisujemy szablon z ilością kopii ustawioną na 2 oraz słowami kluczowymi "pizza" oraz "spaghetti".\n2. Do boniarki "Grill" przypisujemy szablon z ilością kopii ustawioną na 1 oraz słowem kluczowym "stek".\n3. Klient składa zamówienie na dania o nazwach: "pizza italiana", "spaghetti napoli", "ogórkowa", "stek z tuńczyka".\n4. Po kliknięciu w przycisk drukujący bon: z boniarki "Kuchnia" wyjdą dwa takie same bony z daniami "pizza italiana" oraz "spaghetti napoli" na każdym z nich, z boniarki "Grill" natomiast wyjdzie jeden bon z daniem o nazwie "stek z tuńczyka". Ogórkowa w takim scenariuszu nie pojawi się na żadnym bonie!\n5. Aby wydrukować ogórkową należy dodać do którejś z drukarkek szablon POZOSTAŁE DANIA lub dopisać ogórkową do listy słów kluczowych któregoś z używanych już szablonów lub stworzyć nowy szablon i wpisać ogórkową w listę słów kluczowych.\n6. Jeden szablon = jeden bon. Jeśli do boniarki przypiszemy dwa szablony, wówczas wyjdą z niej dwa różne bony w ilościach takich jakie podaliśmy w polu "ilość kopii" (dwa szablony, każdy z ilością kopii dwa wydrukuje łącznie 4 bony). Takie rozwiązanie może być użyteczne w np. przypadku, gdy chcemy drukować na barze zarówno napoje jak i przystawki, jednak na osobnych bonach.',
      allMealsRemainingMealsCollapsible: 'WSZYSTKIE DANIA/POZOSTAŁE DANIA',
      allMealsRemainingMealsCollapsibleDescription:
        'Domyślnie na jeden lokal tworzą się dwa podstawowe, nieusuwalne szablony: WSZYSTKIE DANIA oraz POZOSTAŁE DANIA.\n1. Jeśli szablon WSZYSTKIE DANIA jest przypisany do boniarki, wówczas wyjdzie z niej bon ze wszystkimi pozycjami zamówienia\n2. Jeśli szablon POZOSTAŁE DANIA jest przypisany do którejś z boniarek, wówczas dania niedopasowane do żadnych słów kluczowych zostaną na tej boniarce wydrukowane.\n3. Szablony WSZYSTKIE DANIA zachowują się zawsze tak samo, niezależnie od ich ilości. Jeśli mamy do naszych drukarek podpięte dwa takie szablony, na obu drukarkach wydrukują się bony ze wszystkimi daniami.\n4. Jeśli chcemy, aby jedna boniarka drukowała dwa bony ze wszystkimi daniami zalecanym sposobem jest zwiększenie liczby kopii, a nie tworzenie drugiego szablonu typu WSZYSTKIE DANIA. Drugi szablon należy utworzyć w przypadku, gdy chcemy uzyskać dwa bony ze wszystkimi daniami w dwóch różnych wyglądach.',
      deleteReceiptTemplate: 'Czy na pewno chcesz usunąc ten szablon? Jego dane zostaną trwale usunięte',
      addNewTemplateTemplateType: 'Jaki typ szablonu chcesz dodać?',
      whitelistTemplateType: 'Szablon z listą fraz kluczowych',
      allMealsTemplateType: 'Szablon typu WSZYSTKIE DANIA',
      remainingMealsTemplateType: 'Szablon typu POZOSTAŁE DANIA',
      ALLMEALS: 'WSZYSTKIE DANIA',
    },
    navMain: {
      orders: 'Zamówienia',
      ordersCompletedToday: 'Dzisiejsze zamówienia',
      settings: 'Ustawienia',
      newOrder: 'Nowe zamówienie',
      logout: 'Wyloguj',
      loggedInAs: 'Zalogowany ',
      couriersMap: 'Mapa',
      chooseRestaurant: 'Wybierz restaurację',
      addFunds: 'Doładuj konto',
      newDelivery: 'Nowa dostawa',
      onSite: 'Na miejscu',
      scrapeSuccess: 'Pobieranie w toku',
      confirmMealChangeLoss: 'Czy chcesz porzucić niezapisane zmiany dania?',
      discardChanges: 'Porzuć zmiany',
      saveChanges: 'Zapisz zmiany',
      papuHelp: 'Pomoc Papu.io',
      helpManager: 'Manager działu Customer Service',
      help: 'Pomoc',
      canWeHelp: 'Możemy jakoś pomóc?',
      addOrder: 'Dodaj zamówienie',
      createNewOrder: 'Utwórz nowe zamówienie',
      delivery: 'Dostawa',
      chat: 'Skontaktuj się z nami za pomocą czatu',
    },
    subscriptions: {
      balance: 'Saldo',
      orders: 'zamówień',
      days: 'dni',
      run: 'Uruchom',
      addFunds: 'Doładuj konto',
      acceptTerms: 'Akceptuję {0}',
      terms: 'regulamin',
      termsAcceptRequired: 'Akceptacja regulaminu jest wymagana',
      paymentsHistory: 'Historia doładowań',
      numberOfOrders: 'Liczba zamówień',
      created: 'Data utworzenia',
      value: 'Wartość doładowania',
      inSubscription: 'W pakiecie',
      left: 'Do wykorzystania',
      used: 'Wykorzystana',
      downloadInvoice: 'Pobierz fakturę',
      download: 'Pobierz',
      gross: 'brutto',
      trial: 'Okres testowy',
      paymentTrue: 'Dziękujemy! Twoja płatność została zaakceptowana.',
      paymentFalse:
        'Niestety wystąpił błąd podczas dokonywania płatności. Spróbuj opłacić pakiet ponownie. Jeśli masz jakiekolwiek pytania, uprzejmie prosimy o kontakt.',
      net: 'netto',
      name: 'Nazwa',
      address: 'Adres',
      postal_code: 'Kod pocztowy',
      city: 'Miasto',
      nip: 'NIP',
      email: 'Adres e-mail',
      emailTooltip: 'Na podany adres e-mail zostanie wysłana faktura',
      update: 'Zaktualizuj dane',
      edit: 'Edycja danych firmy',
      editInfo: 'Poniższe dane posłużą do wypełnienia faktury za wykupiony pakiet.',
      required: 'Te pole jest wymagane',
      companySaved: 'Dane firmy zapisano!',
      additionalInvoiceData: 'Dodatkowe dane do faktury',
      additionalInvoiceDataInfo: 'Dane te zostaną dodatkowo umieszczone pod danymi firmy',
    },
    billing: {
      name: 'Imię i nazwisko',
      startedAt: 'Początek okresu',
      manHours: 'Roboczogodziny',
      penalties: 'Potrącenia',
      penalty: 'Potrącenie',
      bonuses: 'Premie',
      bonus: 'Premia',
      advances: 'Zaliczki',
      advance: 'Zaliczka',
      kilometrage: 'Kilometry',
      numberOfOrders: 'Liczba zamówień',
      toBePaidOut: 'Do wypłaty',
      finishedAt: 'Koniec okresu',
      showRemovedEmployees: 'Pokaż usuniętych pracowników',
      hideRemovedEmployees: 'Ukryj usuniętych pracowników',
      filterByEmployee: 'Filtruj po pracowniku',
      closePeriod: 'Rozlicz',
      addAdvance: 'Dodaj zaliczkę',
      addPenalty: 'Dodaj potrącenie',
      addBonus: 'Dodaj premię',
      amount: 'Kwota',
      comment: 'Komentarz',
      cancel: 'Anuluj',
      add: 'Dodaj',
      confirmation:
        'Potwierdzenie wypłaty wynagrodzenia spowoduje wyzerowanie i zamknięcie bieżącego okresu rozliczeniowego.',
      confirm: 'Potwierdź',
      confirmAndPrint: 'Potwierdź i drukuj',
      closed: 'Rozliczony',
      start: 'Start',
      end: 'Koniec',
      operationalCash: 'Środki operacyjne',
      paymentsFromCustomer: 'Oddana gotówka',
      balance: 'Saldo',
      shiftStart: 'Data i godzina rozpoczęcia zmiany',
      shiftFinish: 'Data i godzina zakończenia zmiany',
      initialMileage: 'Początkowy stan licznika',
      finalMileage: 'Końcowy stan licznika',
      kilometers: 'Kilometry',
      mileageStart: 'Stan licznika (start)',
      mileageFinish: 'Stan licznika (koniec)',
      terminalNumber: 'Numer teminala',
      returnedOperationalCash: 'Zwrot środków operacyjnych',
      returnedCashForOrders: 'Zwrot gotówki za zamówienia',
      operationalCashOut: 'Wydanie środków operacyjnych',
      terminalReturned: 'Zwrot terminala',
      save: 'Zapisz',
      startDay: 'Start dnia',
      endDay: 'Koniec dnia',
      newShift: 'Nowa zmiana',
      role: 'Rola',
      noBillingPeriodAlert: 'Uruchom najpierw okres rozliczeniowy dla tego pracownika.',
      noSalaryConfigAlert: 'Przejdź do edycji pracownika i ustaw mu zasady rozliczania.',
      operationalBalance: 'Saldo operacyjne',
      cashFromOrders: 'Gotówka za zamówienia',
      details: 'Szczegóły',
      closeBillingPeriod: 'Zamknij także okres rozliczeniowy',
      period: {
        component: 'Składowa',
        amount: 'Ilość',
        value: 'Wartość',
      },
      pendingOrdersWarning:
        'Nie wszystkie zamówienia zostały zakończone. Zakończenie ich przez pracownika, może wpłynąć na wysokość salda zmiany tego pracownika (nawet jeśli zmiana jest zakończona).',
      billingPeriodIsFinished: 'Okres rozliczeniowy jest zakończony.',
      acceptedCash: 'Przyjęte',
      givenBack: 'Oddane',
      nameLastName: 'Imię i nazwisko',
      date: 'Data',
      type: 'Typ',
      none: 'Brak',
      notAllOrdersWithDA: 'Nie wszystkie zamówienia mają przypisane strefy.',
      ordersToDA: 'Zamówienia do strefy',
      orders: 'Zamówienia',
      operationalCashBalance: 'Saldo środków operacyjnych',
      beginning: 'Początek',
      all: 'Wszystkie',
      closeAndSettle: 'Zamknij i rozlicz okres',
      closedDate: 'Data zakończenia',
      shifts: 'Zmiany',
      shiftEdit: 'Edytuj',
      shiftEditInfo: 'Edycja zmiany jest dostępna tylko dla managera.',
      shiftEditFinishedBillingInfo:
        'Edycja zmiany jest dostępna tylko dla ostatniej zmiany ostatniego okresu rozliczeniowego. Okres rozliczeniowy nie może być zakończony.',
      reopenBillingPeriod: 'Otwórz i popraw okres rozliczeniowy',
      reopenBillingPeriodInfo: 'Edycja okresu rozliczeniowego jest dostępna tylko dla managera.',
      reopenBillingPeriodLastInfo:
        'Edycja okresu rozliczeniowego jest dostępna tylko dla ostatniego okresu rozliczeniowego pracownika.',
    },
    shifts: {
      restaurantCash: 'Środki firmowe',
      value: 'Wartość',
      cashForOrders: 'Gotówka za zamówienia',
      operationalCashBalance: 'Saldo środków operacyjnych',
      sum: 'Suma',
      salary: 'Wynagrodzenie',
      quantity: 'Liczba',
      employeePrice: 'Stawka',
      orders: 'Zamówienia',
      notAllOrdersWithDA: 'Nie wszystkie zamówienia mają przypisane strefy.',
      manHours: 'Roboczogodziny',
      kilometres: 'Kilometry',
      date: 'Data',
      type: 'Typ',
      amount: 'Kwota',
      comment: 'Komentarz',
      none: 'Brak',
      data: 'Dane',
      shiftStart: 'Start zmiany',
      shiftEnd: 'Koniec zmiany',
      startKilometres: 'Kilometry - start',
      endKilometres: 'Kilometry - koniec',
      lasts: 'Trwa',
      no: 'Nr',
      address: 'Adres',
      deliveryArea: 'Strefa dostawy',
      price: 'Cena',
      payment: 'Płatność',
      status: 'Status',
      finishedType: 'Typ zakończenia',
      orderNotAssignedDA: 'Zamówienie nieprzypisane do strefy.',
      beginning: 'Początek',
      end: 'Koniec',
      billingPeriod: 'Okres rozliczeniowy',
      billingPeriodLink: 'Przejdź do',
      shift: 'Zmiana',
    },
    regions: {
      title: 'Strefy dostaw',
      addNewBtnText: 'Dodaj nową strefę',
      noRegions: 'Brak stref dostaw',
      regionName: 'Nazwa strefy',
      active: 'Aktywna',
      save: 'Zapisz',
      cancel: 'Anuluj',
      courierPrice: 'Stawka kuriera',
      freeDeliveryFromValue: 'Darmowa dostawa od',
      minOrderValue: 'Minimalna wartość zamowienia',
      deliveryPrice: 'Cena dostawy',
      errors: {
        noPolygon: 'Brak narysowanej strefy. Narysuj strefę na mapie.',
        polygonMinVertexes: 'Strefa musi posiadać min. 3 wierzchołki.',
        areaRadiusInput: 'Lub podaj promień',
      },
      list: 'Lista stref',
      map: 'Mapa',
      regionUpdateSuccess: 'Strefa dostaw zapisana pomyślnie',
    },
    invoiceModal: {
      titleEdit: 'Edytuj fakturę',
      title: 'Wydrukuj fakturę',
      companyName: 'Nazwa firmy',
      streetAddress: 'Adres',
      postalCode: 'Kod pocztowy',
      city: 'Miasto',
      taxIDNumber: 'NIP',
      billNumber: 'Nr paragonu',
      hideDetails: 'Bez szczegółów',
      allFieldsRequired: 'Pola zaznaczone * są wymagane',
      hideName: 'Nazwa pozycji',
      hideNames: {
        default: '„Usługa gastronomiczna”',
        custom: 'Własna',
      },
      paymentType: 'Sposób zapłaty',
      paymentTypes: {
        order: 'Z zamówienia',
        custom: 'Własny',
        cash: 'gotówka',
        card: 'karta',
        paid: 'online',
      },
      sendInvoiceByEmail: 'Wyślij fakturę na e-mail',
      printInvoice: 'Wydrukuj fakturę',
    },
    administration: {
      settings: 'Ustawienia',
      localizationConfig: {
        message: 'Ustawienia restauracji',
        localizationName: 'Nazwa restauracji',
      },
      ordersSummaryTimeFilters: {
        message: 'Domyślne filtry daty na podsumowaniu zamówień',
        weekday: 'Dzień tygodnia',
        start: 'Start',
        end: 'Koniec',
        monday: 'Poniedziałek',
        tuesday: 'Wtorek',
        wednesday: 'Środa',
        thursday: 'Czwartek',
        friday: 'Piątek',
        saturday: 'Sobota',
        sunday: 'Niedziela',
      },
      integrationsConfig: {
        message: 'Wybierz integracje, z których zamówienia mają być drukowane automatycznie',
        receiptAuto: 'Automatyczne drukowanie bonów',
        phone: 'Zamówienia telefoniczne',
        pyszne: 'Zamówienia pyszne.pl',
        restaumatic: 'Zamówienia restaumatic.com',
        upmenu: 'Zamówienia upmenu.pl',
        erestaurant: 'Zamówienia erestauracji',
        ubereats: 'Zamówienia ubereats.com',
        groupon: 'Zamówienia groupon.pl',
        new_erestaurant: 'Zamówienia erestauracji',
        ordersmart: 'Zamówienia OrderSmart',
      },
      integrationsNewOrderStatus: {
        message: 'Wybierz, do której zakładki mają wpadać nowe zamówienia z integracji',
      },
      orders: {
        message: 'Zamówienia',
        orderOnSite: 'Zamówienia na miejscu',
        printBillCheck: 'Domyślne zaznaczenie opcji drukowania paragonu',
        finishOrderCheck: 'Domyślne zaznaczenie opcji zamykania zamówienia',
        orderDelivery: 'Zamówienia dostawy',
        printBillDeliveryCheck: 'Domyślne zaznaczenie opcji drukowania paragonu',
        orderDetails: 'Szczegóły zamówienia',
        closeOrderOnPrintBill: 'Zamknij zamówienie po naciśnięciu przycisku "Paragon"',
        ordersCommonConfig: 'Zamówienia - ogólne',
        manualSourceChoiceRequired:
          'Wymagaj wybrania źródła zamówienia dla nowych zamówień i ukryj źródło Domyślne ' +
          'na liście źródeł zamówienia',
      },
      deliveryOnBill: {
        message: 'Dostawa na paragonie',
        delivery: 'Dostawa',
        vatRate: 'Stawka VAT',
        positionNameOnBill: 'Nazwa pozycji na paragonie (opcjonalne)',
        skipDeliveryOnBillAndInvoice: 'Pomiń dostawę na paragonie i fakturze',
      },
      tip: {
        message: 'Napiwki na paragonie',
        tips: 'Napiwki',
        vatRate: 'Stawka VAT',
        positionNameOnBill: 'Nazwa pozycji na paragonie (opcjonalnie)',
        skipTipOnBillAndInvoice: 'Pomiń napiwek na paragonie i fakturze',
      },
      defaultPosView: {
        message: 'Wybierz widok podstawowy systemu Papu.io',
        defaultView: 'Widok podstawowy',
        map: 'Mapa',
        list: 'Lista zamówień',
      },
      halfPizza: {
        message: 'Algorytm obliczania ceny pizzy pół na pół',
        halfPizzaPrice: 'Cena pizzy pół na pół',
        additionalPrice: 'Dodatkowa kwota za pizzę pół na pół',
        halfPizzaAvgPrice: 'Średnia z cen obu połówek + cena dodatków',
        halfPizzaMoreExpensive: 'Cena droższej połówki + cena dodatków',
        halfPizzaMcgPrice: 'Cena dodatków dla połówki pizzy pół na pół',
        halfPizzaMcgHalfPrice: 'Połowa ceny dodatku',
        halfPizzaMcgFullPrice: 'Pełna cena dodatku',
      },
      shifts: {
        message: 'Zmiany',
        oneDayBillingPeriods: 'Jednodniowe okresy rozliczeniowe',
        closeBillingPeriod: 'Domyślnie zamykaj okres rozliczeniowy ze zmianą',
      },
      summary: {
        message: 'Raporty',
        summaries: 'Ustawienia dotyczące przesyłania raportów dobowych na e-mail',
        summary_description:
          'Raporty dobowe - zawierające informacje o zakończonych zamówieniach dla ostatniego ' +
          'dnia względem ustawionych filtrów czasowych',
        summary_fiscal_description:
          'Raporty dobowe fiskalne - zawierające informacje o wydrukowanych zamówieniach ' + 'dla ostatniej doby',
      },
      integrationsSettings: {
        message: 'Integracje',
        includeUberPromotionDescription:
          'Uwzględniaj kwotę oferty UberEats (kod) w cenie zamówienia i wartości ' + 'paragonu jako rabat',
        subtractStampcardFromPrice: 'Odejmij wartość pieczątek z Pyszne od wartości zamówienia',
        glovoServiceChargeVat: 'Stawka VAT opłaty serwisowej Glovo',
        gloVoServiceChargeNameOnBill: 'Nazwa pozycji opłaty serwisowej Glovo na paragonie (opcjonalne)',
        takeawayLabel: 'Pyszne',
      },
      notifications: {
        message: 'Powiadomienia',
        newOrder: 'Nowe zamówienie',
        soundNotification: 'Powiadomienie dźwiękowe',
      },
      settingsSaved: 'Ustawienia zapisane',
      saveChanges: 'Zapisz zmiany',
    },
    erestaurant: {
      erestaurant: 'Ustawienia E-Restauracji',
      message: 'Ustawienia dotyczące Twojej E-Restauracji',
      link: 'Link',
      preview: 'Podgląd',
      logo: 'Logo strony E-restauracji',
      background: 'Tło strony E-restauracji',
      icon: 'Ikona strony E-restauracji - widoczna w karcie strony',
      orderEmail: 'Adres e-mail wymagany przy zamówieniu - na ten adres wysyłane będzie potwierdzenie zamówienia',
      differentPrices: 'Czy E-restauracja dla {restaurant} ma mieć inne ceny dań niż dostępne w POSie?',
      active: 'E-Restauracja włączona?',
      activeTooltip: 'Włącz/wyłącz cały sklep internetowy',
      complexMealsAvailable: 'Czy dania pół na pół mają być dostępne w E-Restauracji?',
      showRestaurantEmail: 'Wyświetl adres e-mail restauracji',
      sendOrderConfirmationEmailToBCC:
        'Czy wysyłać kopię wiadomości e-mail z potwierdzeniem zamówienia do restauracji?',
      brandActive: 'E-Restauracja włączona dla restauracji {restaurant}?',
      brandActiveTooltip: 'Włącz/wyłącz e-restaurację dla restauracji {restaurant}',
      openingHours: 'Godziny otwarcia E-Restauracji dla {restaurant}',
      ordersTimeLimit: {
        message: 'Ustawienia dotyczące czasu zamawiania w E-Restauracji dla {restaurant}',
        pickup: 'Zamówienia z odbiorem własnym',
        delivery: 'Zamówienia z dostawą',
        minTime:
          'Minimalny czas od złożenia zamówienia do jego zrobienia. Ta wartość czasu ' +
          'dodawana jest do aktualnego czasu dla zamówień "Na wybraną godzinę", tworząc minimalną godzinę, ' +
          'na którą można złożyć zamówienie wybranego dnia.',
        maxTime:
          'Maksymalny czas złożenia zamówienia przed zamknięciem restauracji. Ta wartość czasu odejmowana ' +
          'jest od czasu zamknięcia restauracji, tworząc maksymalną godzinę, na którą można złożyć zamówienie ' +
          'wybranego dnia.',
      },
      minutes: 'minut',
      choose: 'Wybierz',
      loadFile: 'Załaduj plik',
      analytics: 'Analityka',
      googleAnalyticsInfo: 'Umieść tutaj ID z GoogleAnalytics (UA-XXXXXXXXX-X)',
      googleTagManagerInfo: 'Umieść tutaj ID z Google Tag Manager (GTM-XXXXXXX)',
      facebookPixelId: 'Identyfikator Facebook Pixel',
      facebookPixelIdInfo: 'Umieść tutaj ID z Facebook Pixel',
      facebookPixelMetaTag: 'Identyfikator Facebook Pixel meta-tag (pole "content")',
      facebookPixelMetaTagInfo: 'Umieść tutaj identyfikator meta-tag z Facebook Pixel (pole content)',
      imageResolution: 'Minimalne zalecane wymiary: 500 x 500 pikseli.',
      title: 'Tytuł strony',
      description: 'Opis strony (Meta tag DESCRIPTION)',
      descriptionHtml: 'Opis strony jako HTML',
      descriptionHtmlTooltip: 'Opis strony wyświetlany nad stopką strony. Może zawierać kod HTML bez skryptów.',
      keywords: 'Słowa kluczowe',
      descriptionTooltip:
        'Meta tag DESCRIPTION. Krótki opis strony podsumowujący jej treść. Głównym zadaniem zoptymalizowanego opisu jest zwiększenie współczynnika klikalności. Maksymalna ilość znaków 155.',
      keywordsTooltip:
        'Tagi te opisują zawartość witryny. Słowa kluczowe są zwykle pisane małymi literami i oddzielane przecinkami. Nie są widoczne na stronie, tylko w jej kodzie. Pomagają w pozycjonowaniu strony.',
      erestoDisableDelivery: 'Zablokuj zamówienia na dowóz w {restaurant}',
      erestoDisableTakeaway: 'Zablokuj zamówienia z odbiorem własnym w {restaurant}',
    },
    permissions: {
      permissionsSettings: 'Ustawienia Zezwoleń',
      ordersPermissions: 'Zezwolenia dotyczące zamówień',
      pastHistoryOrdersView:
        'Zdecyduj, jak stare zamówienia powinni widzieć Twoi pracownicy (ilość dni wstecz) ' +
        'na widoku archiwum zamówień i podsumowanie zamówień',
      days: 'dzień | dni',
      updateFinishedOrders: 'Pracownik może zmieniać sposób zakończenia i formę płatności zakończonego zamówienia.',
      orderPaymentPaidChange:
        'Pracownik może zmieniać sposób płatności z "Zapłacone" na inną oraz z innej na "Zapłacone".',
      employeesPermissions: 'Zezwolenia dotyczące pracowników',
      manageEmployees: 'Pracownik może zarządzać listą pracowników.',
      createPaidOrders: 'Pracownik może dodawać zamówienia ze sposobem płatności "Zapłacone".',
    },
    menu: {
      menuSave: 'Zapisz menu',
      menuEdit: 'Zarządzanie menu',
      imageLoading: 'Przesyłanie obrazu, proszę czekać',
      defaultSizeName: 'Domyślny',
      mealsCount: 'Ilość dań w tej kategorii',
      sizesCount: 'Ilość rozmiarów w tej kategorii',
      categorySizeMealsCount: 'Ilość dań w wybranej kategorii w tym rozmiarze',
      sizeMealsCount: 'Ilość dań w tym rozmiarze',
      choiceGroupMealsCount: 'Ilość dań posiadających tą grupę',
      menuNotSaved: 'Zmiany nie zostały zapisane. Zapisać?',
      save: 'Zapisz',
      saving: 'Zapisywanie...',
      savingWait: 'Trwa zapisywanie. Proszę czekać...',
      leaveWithoutSaving: 'Nie zapisuj i wyjdź z panelu edycji',
      menuSavingError: 'Błąd zapisu menu',
      menuSaved: 'Menu zostało zapisane',
      menuChangesAfterWaiterRelog:
        'Aby wprowadzone zmiany w menu pojawiły się na koncie kelnera, ' +
        'prosimy o wylogowanie się z konta kelnera i ponowne zalogowanie',
      saveMenu: 'Zakończ edycję i zapisz menu',
      exitMenu: 'Wyjdź z panelu zarządzania menu',
      editMenuExternalSource: 'Menu zewnętrzne',
      vatRate: 'Stawka VAT',
      noCategories: 'Brak kategorii',
      notFoundMeal: 'Nie znaleźliśmy szukanej frazy w daniach',
      deleteMsgBox: {
        confirm: 'Czy na pewno usunąć element?',
        ok: 'Tak',
        cancel: 'Nie',
      },
      publish: 'Opublikuj',
      published: 'Opublikowano',
      publishModalText: 'Czy przed wyjściem opublikować zmiany menu?',
      publishModalMenuChangeText: 'Czy przed wyborem innego menu chcesz opublikować zmiany?',
      choiceGroups: {
        groupName: 'Nazwa grupy',
        cloneGroup: 'Sklonuj grupę',
        required: 'Wymagana',
        requiredInfo: 'Zaznacz, jeśli wybranie modyfikatora z tej grupy ma być wymagane',
        defaultScge: 'Domyślnie wybrany modyfikator',
        defaultScgeInfo: 'Będzie to zawsze początkowo wybrany modyfikator',
        add: 'Dodaj nową kategorię',
        name: 'Nazwa',
        price: 'Cena',
        addNext: 'Dodaj kolejny',
        availableInEresto: 'Grupa dostępna w E-Restauracji?',
        mealsWithSelectedChoiceGroups: 'Dania, w których występuje wybrana grupa dodatków',
        chooseCategoryToEdit: 'Wybierz kategorię, dla której chcesz zmienić ',
        chooseSizeToEdit: 'Wybierz rozmiar, dla którego chcesz zmienić ',
        addGroupForSelectedSize: 'Dodaj nową grupę',
        increaseAllEntriesPrice: 'Zwiększ cenę wszystkich o 1',
        decreaseAllEntriesPrice: 'Zmniejsz cenę wszystkich o 1',
        changeAllEntriesPrice: 'Zmień cenę wszystkich o:',
        multiChoiceGroupTitle: 'Dodatki',
        singleChoiceGroupTitle: 'Modyfikatory',
        noEntries: 'Nie możesz zapisać grupy bez żadnych pozycji. Dodaj przynajmniej jedną pozycję.',
        noSelectedMeals: 'Musisz wybrać przynajmniej jedno danie, do którego zostanie przypisana ta grupa.',
        multiChoiceGroupsTitle: 'Grupy dodatków',
        multiChoiceGroup: 'Grupa dodatków',
        noMultiChoiceGroups: 'Brak grup dodatków.',
        singleChoiceGroupsTitle: 'Grupy modyfikatorów',
        singleChoiceGroup: 'Grupa modyfikatorów',
        noSingleChoiceGroups: 'Brak grup modyfikatorów.',
        informations: 'Informacje',
      },
      meal: {
        title: 'Dania',
        ingredientDuplicatedInfo: 'Danie nie może zawierać więcej niż 1 składnik o tej samej nazwie',
        name: 'Nazwa dania',
        size: 'Rozmiar',
        ingredients: 'Składniki dania - z czego składa się danie',
        addIngredient: 'Dodaj składnik',
        availableInEresto: 'Danie dostępne w E-Restauracji?',
        description: 'Opis dania',
        visibleInEresto: 'Obraz dania widoczny w E-Restauracji',
        chooseCategoryToEdit: 'Wybierz kategorię, dla której chcesz zmienić dania',
        add: 'Dodaj nowe danie',
        price: 'Cena',
        erestoPrice: 'Cena w E-Restauracji',
        noPrices: 'Pole z ceną dania nie może być puste.',
        noMealSizes: 'Danie musi zostać przypisane przynajmniej do jednego rozmiaru.',
        mealNameExists: 'Danie o takiej nazwie już istnieje w tej kategorii.',
      },
      sizes: {
        title: 'Rozmiary',
        name: 'Nazwa',
        defaultSize: 'Rozmiar domyślny?',
        defaultTooltipText: 'Będzie to zawsze początkowo wybrany rozmiar przy tworzeniu nowego zamówienia.',
        fiftyFiftyMeals: 'Czy dania w rozmiarze można dzielić, np. pizza pól na pół?',
        availableInEresto: 'Rozmiar dostępny w E-Restauracji',
        mergeSizes: 'Scal inne rozmiary do tego rozmiaru',
        selectMergeSizes: 'Wybierz rozmiar, który chcesz scalić do tego rozmiaru',
        add: 'Dodaj nowy rozmiar',
        deleteWarning:
          'Wybrany rozmiar z listy zostanie usunięty, a jego dania zostaną dodane do aktualnego rozmiaru (szary).',
      },
      categories: {
        title: 'Kategorie',
        add: 'Dodaj nową kategorię',
        name: 'Nazwa kategorii',
        defaultCategory: 'Kategoria domyślna?',
        defaultTooltipText: 'Będzie to zawsze początkowo wybrana kategoria przy tworzeniu nowego zamówienia.',
        default: 'Domyślna',
        availableInEresto: 'Kategoria dostępna w E-Restauracji',
        visibleInEresto: 'Obraz kategorii widoczny w E-Restauracji',
        addSize: 'Dodaj rozmiary',
        addNextSize: 'Dodaj kolejny rozmiar',
        moreThanOneSizeInCategory: 'Czy dania w kategorii mają więcej niż 1 rozmiar?',
        noCategories: 'Brak kategorii',
        kdsStatuses: 'Statusy dań w KDS',
        kdsStatusesModalTitle: 'Wybierz zestaw statusów',
        kdsStatusesTooltip:
          'Wybierz zestaw statusów dań, które będą obowiązywać w aplikacji KDS dla wszystkich dań z tej kategorii.',
      },
      states: {
        saved: 'Element zapisano.',
        added: 'Dodano element.',
        deleted: 'Element usunięto.',
      },
      edit: {
        categories: 'Edycja kategorii',
        sizes: 'Edycja rozmiaru',
        multi_choice_groups: 'Edycja grupy dodatków',
        single_choice_groups: 'Edycja grupy modyfikatorów',
        meals: 'Edycja dania',
        noEditElementTitle: 'Brak elementu do edycji.',
        noEditElementBody: 'Wybierz element z menu, aby go edytować.',
      },
      new: {
        categories: 'Nowa kategoria',
        sizes: 'Nowy rozmiar',
        multi_choice_groups: 'Nowa grupa dodatków',
        single_choice_groups: 'Nowa grupa modyfikatorów',
        meals: 'Nowe danie',
      },
    },
    mealSets: {
      mealSetsSettings: 'Zestawy',
      discount: 'Zniżka',
      count: 'Ilość',
      category: 'Kategoria',
      sizes: 'Rozmiary',
      meals: 'Dania',
      newMealSet: 'Stwórz nowy zestaw',
      addMealSet: 'Zapisz zestaw',
      editMealSet: 'Edycja zestawu',
      mealSetName: 'Nazwa zestawu',
      mealSetNameInput: 'Wprowadź nazwę zestawu',
      mealSetItems: 'Elementy zestawu',
      created: 'Data utworzenia',
      brandName: 'Restauracja',
      name: 'Nazwa',
      items: 'Elementy zestawu',
      type: 'Typ zestawu',
      normal: 'Normalny',
      halfByHalf: 'Pół na pół',
      mealSetInEresto: 'Czy zestaw ma być dostępny w E-Restauracji?',
      mealSetDiscount: 'Zniżka na zestaw',
      backToMealSetsList: 'Wróć do zestawów',
      saveMealSet: 'Zapisz zestaw',
      addMealSetItem: 'Dodaj element zestawu',
      halfPizzaMealSetItemInfo: 'W zestawie pół na pół oba elementy zestawu są takie same.',
      mealSetItemQuantity: 'Ilość sztuk dań spośród wybranych poniżej kategorii/rozmiarów/dań',
      mealSetItemDiscount: 'Zniżka na element zestawu',
      chooseCategory: 'Wybierz kategorię',
      chooseSizes: 'Wybierz rozmiary',
      chooseMeals: 'Wybierz dania',
      all: 'Wszystkie',
      checkUncheckAll: 'Zaznacz/Odznacz wszystkie',
      backToMealSet: 'Wróc do zestawu',
      saveMealSetItem: 'Zapisz element zestawu',
      allSizesOfCategory: 'Wszystkie rozmiary kategorii',
      allMealsOfCategory: 'Wszystkie dania kategorii',
      allMealsOfSizes: 'Wszystkie dania rozmiarów',
      noMealSetItems: 'Brak produktów w zestawie',
      item: {
        itemCount: 'Ilość sztuk dań spośród wybranych poniżej kategorii/rozmiarów/dań',
        itemDiscount: 'Zniżka na element zestawu',
        chooseItemCategory: 'Wybierz kategorię elementu',
        chooseItemSizes: 'Wybierz rozmiary elementu',
        chooseItemMeals: 'Wybierz dania dostępne do wyboru dla tego elementu zestawu',
      },
      discountSort:
        'Zniżki dla dań o tych samych kategoriach i rozmiarach są sortowane w celu przypisania najmniejszej zniżki do najdroższego dania.',
    },
    mealsetEdit: {
      title: 'Zestawy',
    },
    discounts: {
      discountsSettings: 'Rabaty',
      addDiscount: 'Dodaj kod rabatowy',
      discountView: 'Podgląd rabatu',
      noDiscount: 'Brak zniżki',
      list: {
        new: 'Nowy',
        created: 'Data utworzenia',
        fromDatetime: 'Od',
        toDatetime: 'Do',
        used_codes: 'Zużyte kody',
        usage: 'Zużycie',
        discountType: 'Typ zniżki',
        discountValue: 'Zniżka',
        active: 'Aktywny',
        details: 'Szczegóły',
        discountChoiceValue: 'Kwota rabatu',
        discountChoicePercent: 'Zniżka procentowa',
        discountChoicePromotionalPrice: 'Cena promocyjna',
        emptyList: 'Brak rabatów',
      },
      edit: {
        noLimit: 'Bez limitu',
        limitedTime: 'Limit czasowy',
        discountValuePrice: 'Rabat kwotowy',
        discountValuePercent: 'Rabat procentowy',
        discountPromotionalPrice: 'Cena promocyjna',
        provideDiscountValue: 'Podaj kwotę rabatu',
        provideDiscountPercent: 'Podaj wartość rabatu procentowego',
        providePromotionalPrice: 'Podaj kwotę promocyjną',
        codesToGenerate: 'Ilość kodów do wygenerowania',
        codesToGeneratePlaceholder: 'Podaj liczbę kodów do wygenerowania',
        perCodeUsage: 'Ile razy każdy wygenerowany kod może zostać użyty',
        perCodeUsagePlaceholder: 'Podaj liczbę kodów',
        addDiscount: 'Dodaj promocję',
        removeDiscount: 'Usuń promocję',
        confirmRemoveDiscount: 'Czy na pewno chcesz usunąć promocję?',
        codes: 'Kody',
      },
    },
    notifications: {
      deletable: 'Po kliknięciu w X ta wiadomość zostanie trwale usunięta',
    },
    regeocodeOrders: {
      orderIsAlreadyInRegeocode: 'Zamówienie już jest w trakcie ponowniej geolokalizacji',
      orderRegeocodeIsBlocked: 'Ponowna geolokalizacja jest zablokowana dla zamówienia',
      regeocodeOrdersSuccess: 'Ponowna geolokalizacja zamówień',
      regeocodeOrdersError: 'Wystąpił błąd ponownej geolokalizacji dla zamówienia',
      relocating: 'Ponowna geolokalizacja...',
      relocatingError: 'Nie udało się ponownie zgeolokalizować adresu zamówienia. Skontaktuj się z pomocą techniczną.',
    },
    splitBill: {
      split: 'Podziel',
      splitIntoEqualParts: 'Podziel na równe części',
      extractTheAmount: 'Wydziel kwotę',
      addBill: 'Dodaj rachunek',
      addFirstBill: 'Dodaj pierwszy rachunek',
      emptyBillDescription: 'Paragon jest pusty.\n Dodaj pozycję z zamówienia.',
      emptyBaseBillDescription: 'Brak pozycji do rozliczenia.',
      moveOrderItemsHeading: 'Ile sztuk pozycji przenieść?',
      numberOfItems: 'Ilość pozycji',
      splitBillHeading: 'Na ile rachunków podzielić?',
      numberOfBills: 'Ilość rachunków',
      enterAmount: 'Podaj kwotę',
      enterPercentage: 'Podaj procent',
      enterQuantity: 'Podaj ilość',
      selectPayment: 'Wybierz formę płatności',
      mark: 'R',
      bill: 'Rachunek',
      gettingBills: 'Wczytuję rachunki',
      deleteBillModal: {
        title: 'Usuwanie rachunku',
        description:
          'Czy na pewno chcesz usunąć rachunek? \n Wszystkie pozycje z rachunku zostaną przeniesione do rachunku głównego.',
      },
      status: {
        ended: 'Zakończone',
        printed: 'Wydrukowane',
        printError: 'Błąd wydruku',
      },
      error: {
        closeBill: 'Podczas zamykania zamówienia wystąpił błąd.',
        movePosition: 'Wybrany rachunek jest nieedytowalny. Wybierz inny rachunek bądź utwórz nowy.',
      },
    },
    integrations: {
      active: 'Aktywna',
      wolt: {
        on: 'Włącz restauracje na Wolt',
        off: 'Wyłącz restauracje na Wolt',
      },
      woltConfirmation: 'Proces przełączania restauracji na Wolt został zakończony.',
      woltFailure: 'Wystąpił błąd podczas przełączania restauracji na Wolt.',
      online: 'Restauracja włączona',
      offline: 'Restauracja wyłączona',
      updateMenu: 'Aktualizacja menu',
      updateMenuConfirmation:
        'Proces aktualizacji menu z Dotykačka został rozpoczęty. Aby zmiany były widoczne prosimy wylogować się i zalogować ponownie za ok 1 minutę.',
      updateMenuFailure: 'Wystąpił błąd podczas pobierania menu.',
      noIntegrationInfo: 'Nie masz włączonej integracji z ',
    },
    mapping: {
      mappingMenu: 'Mapowanie menu',
      mapping: 'Mapowanie',
      mapIt: 'Zmapuj',
      checkMapping: 'Sprawdź',
      bind: 'Mapuj',
      ignore: 'Zignoruj',
      unblock: 'Odblokuj',
      editMapping: 'Edytuj mapowanie',
      selectMealToMap: 'Wybierz pozycję do zmapowania',
      showUnmaped: 'Pokaż pozycje do zmapowania',
      showMapped: 'Pokaż zmapowane pozycje',
      showIgnored: 'Pokaż zignorowane pozycje',
      dynamicModeTitle: 'Mapowanie brakujących pozycji w zamówieniu nr: ',
      showMappedPositions: 'Pokaż zmapowane pozycje',
      meals: 'Dania',
      singleChoice: 'Modyfikatory',
      multiChoice: 'Dodatki',
      mappingBtnTooltip:
        'Danie lub jego dodatek/modyfikator nie jest zmapowany. Przejdź do mapowania, aby zmapować te pozycje.',
      mappingBtnTooltipMapped: 'Przejdź do mapowania, aby sprawdzić połączenia tego dania.',
      table: {
        positionFromIntegration: 'Pozycja z integracji',
        positionFromMenu: 'Pozycja z menu',
        noMappedPositions: 'Brak zmapowanych pozycji',
        noPositionsToBind: 'Brak pozycji do zmapowania',
        noMapping: 'Brak mapowania',
        noPositionInMenu: 'Brak pozycji w menu domyślnym',
        mealsInGroup: 'Dania w grupie',
      },
      remove: {
        removeMapping: 'Usuń mapowanie',
        promptQuestion: 'Czy na pewno chcesz usunąć mapowanie z tej pozycji?',
        success: 'Mapowanie usunięte z pozycji: ',
      },
      mappingSuccess: 'Pozycja zmapowana',
      ignoreMappingSuccess: 'Pozycja zignorowana - nie będzie wysyłana do integracji',
      sourceButtonAllLabel: 'Wszystkie',
      isArchivedWarningTooltip: 'Danie/dodatek/modyfikator, na który ta pozycja została zmapowana, została usunięta.',
    },
    workstations: {
      title: 'Stanowiska',
      addNewWorkstation: 'Dodaj nowe stanowisko',
      activeWorkstation: 'Aktywne',
      noWorkstation: 'Brak stanowisk',
      userLogout: 'Wylogowany',
    },
    workstationEdit: {
      form: {
        name: 'Nazwa stanowiska',
        color: 'Kolor',
        user: 'Przypisani użytkownicy',
        mealCategories: 'Kategorie dań',
        selectMealCategories: 'Wybierz kategorie dań',
      },
      addButton: 'Dodaj stanowisko',
      saveButton: 'Zapisz stanowisko',
      workstationDeleted: 'Stanowisko usunięte',
      workstationUpdated: 'Stanowisko pracy zaaktualizowane',
    },
    kds: {
      mealStatus: {
        todo: 'Oczekuje',
        doing: 'W trakcie',
        inOven: 'W piecu',
        done: 'Gotowe',
      },
    },
  },
  mealset_in_erestaurant_info: 'Niektóre pozycje z menu mogą być niedostępne dla e-restauracji.',
  choose_brand: 'Wybierz brand',
  package_settings: 'Ustawienia opakowań',
  package_vat_rate: 'Stawka VAT',
  packages: 'Packages',
  search_by: 'Szukaj po',
  order_number: 'Numer zamówienia',
  address: 'Adres',
  customer_name: 'Nazwa klienta',
  phone_number: 'Numer telefonu',
  finished_type_translated: 'Status zakończenia',
  payment_translated: 'Metoda płatności',
  price: 'Cena',
  status: 'Status',
  user: 'Użytkownik',
  change: 'Zmiana',
  field: 'Pole',
  value: 'Wartość',
  change_date: 'Data zmiany',
  old_value: 'Stara wartość',
  new_value: 'Nowa wartość',
  accepted: 'Zaakceptowane',
  finished: 'Zakończone',
  order_changes_history: 'Historia zmian zamówienia',
  delivering_in: 'W trakcie dostawy',
  new: 'Nowe',
  picked_up: 'Odebrane przez kuriera',
  city: 'Miasto',
  report_sugestion: 'Zgłoś uwagę',
  need_help: 'Potrzebujesz pomocy?',
  description: 'Opis',
  no_results: 'Brak wyników',
  address_hint_general_error: 'Nie udało się pobrać podpowiedzi.',
  erestaurant: 'E-Restauracja',
  erestaurant_banner_url: 'Link przekierowywujący z banera',
  erestaurant_banner_url_tooltip:
    'Po klieknięciu w baner użytkownik zostanie przekierowany na podany adres URL. Może to być dowolny adres strony internetowej lub link do pozycji w menu.',
  edit_geo_modal_tip: 'Kliknij na mapie aby wskazać nowe współrzędne.',
  latitude: 'Szerokość geograficzna',
  longitude: 'Długość geograficzna',
  external_map_link: 'Link do punktu na mapach targeo lub google',
  actual: 'Aktualne',
  edit_geolocalization: 'Edytuj współrzędne adresu',
  delivery_area: 'Strefa dostawy',
  courier_in_base: 'W bazie',
  courier_coming_back: 'Wraca',
  courier_in_delivery: 'W dostawie',
  courier_free: 'Wolny',
  courier_busy: 'Zajęty',
  newRecommendation: 'Nowa rekomendacja',
  assignCourierToOrder: 'Przypisz kuriera do zamówienia',
  sendCourierWithOrders: 'Wyślij kuriera z zamówieniami',
  reject: 'Odrzuć',
  kitchen_settings: 'Ustawienia kuchni',
  save: 'Zapisz',
  save_successfully: 'Zapisano pomyślnie',
  deactivate: 'Dezaktywuj',
  deactivate_meal_in_eresto: 'Dezaktywacja pozycji w E-resto',
  deactivate_meal_in_eresto_tooltip:
    'Dezaktywacja tej pozycji w e-resto, spowoduje dezaktywację następujących zestawów',
  notification: 'Powiadomienie',
  notificationTypes: {
    manual: {
      title: 'Powiadomienie',
    },
    automatic: {
      title: 'Powiadomienie',
    },
    'connection-failed': {
      title: 'Błąd połączenia z drukarką',
    },
    'connection-established': {
      title: 'Połączenie z drukarką zostało przywrócone',
    },
    printing: {
      title: 'Wystąpił problem z drukowaniem PTU',
    },
    'subscription-ends': {
      title: 'Subskrypcja zakończona',
    },
    'new-feature': {
      title: 'Sprawdź nowe funkcje',
    },
    regeocode: {
      title: 'Regeolokalizacja zamówienia',
    },
    'order-canceled': {
      title: 'Zamówienie zostało anulowane',
    },
    alert: {
      title: 'Uwaga!',
    },
    '7-days-subscription-ends': {
      title: 'Subskrypcja zakończy się za 7 dni!',
    },
    '3-days-subscription-ends': {
      title: 'Subskrypcja zakończy za 3 dni!',
    },
    '1-day-subscription-ends': {
      title: 'Subskrypcja zakończy za 1 dni!',
    },
    '1-hour-subscription-ends': {
      title: 'Subskrypcja zakończy za 1 godzinę!',
    },
  },
  provide_external_map_url: 'Podaj link do punktu na zewnętrznej mapie np.',
  coordinates_will_be_reassigned_from_url:
    'Skopiuj adres strony z przeglądarki. Współrzędne geograficzne zostaną nadpisane tymi z linku',
  fetch_coordinates: 'Pobierz współrzędne',
  no_delivery_area: 'Brak strefy',
  fetching_coordinates_from_url_error: 'Nie udało się pobrać współrzędnych z linku',
  external_url_is_invalid: 'Link jest nieprawidłowy. Spróbuj podać link z Google Maps lub Targeo.',
  geolocation_for_order_saved: 'Geolokalizacja dla zamówienia {order_number} została zapisana.',
  or: 'lub',
  enlargeMap: 'Powiększ mapę',
  fitMapToOrders: 'Dopasuj mapę do zamówień',
  showDeliveryAreas: 'Pokaż strefy dostawy',
  update_delivery_price_info_by_hand:
    'Cena dostawy nie zaktualizuje się w zamówieniu automatycznie. Jeśli chcesz ją zmienić, edytuj zamówienie ręcznie.',
  open_in_external_map: 'Otwórz adres w mapie zewnętrznej',
  default: 'Domyślne',
  order_price: 'Cena zamówienia',
  coordinates: 'Współrzędne',
  new_coordinates: 'Nowe współrzędne',
  edited: 'Edytowano',
  cancel: 'Anuluj',
  delete: 'Usuń',
  address_verified_and_corrected_manually: 'Adres zweryfikowany i poprawiony ręcznie',
  delete_address_from_customer: 'Usuń adres klienta',
  searching_customers: 'Szukam klientów...',
  searching_delivery_area: 'Szukam strefy dostawy...',
  driver_will_get_address_not_coordinates: 'Do aplikacji kuriera zostanie wysłany ten adres, a nie współrzędne.',
  coordinates_updated_from_link: 'Współrzędne zostały zaktualizowane z linku!',
  no_coordinates_found: 'Nie znaleziono współrzędnych dla tego linku.',
  error_resolving_short_url: 'Błąd wczytania skróconego linku.',
  how_to_get_external_map_link: 'Jak uzyskać współrzędne z mapy zewnętrznej?',
  google_maps: 'Google Maps',
  targeo_map: 'Mapa Targeo',
  to_get_coordinates_from_google_maps_link: 'Aby pobrać współrzędne z Google Maps',
  to_get_coordinates_from_targeo_map_link: 'Aby pobrać współrzędne z Map Targeo',
  geo_edit_google_instruction_1: 'Otwórz',
  geo_edit_google_instruction_2: 'Znajdź poprawne współrzędne dla danego adresu',
  geo_edit_google_instruction_3: 'Kliknij na wybranym adresie lub w miejscu poprawnych współrzędnych',
  geo_edit_google_instruction_4: 'Kliknij "Udostępnij"',
  geo_edit_google_instruction_5: 'Skopiuj otrzymany link',
  geo_edit_google_instruction_6: 'Skopiowany link wklej w pole "Link do punktu na mapach targeo lub google"',
  geo_edit_google_instruction_7: 'Nowe współrzędne zostaną nadpisane tymi z linku',
  geo_edit_targeo_instruction_1: 'Otwórz',
  geo_edit_targeo_instruction_2: 'Znajdź poprawne współrzędne dla danego adresu',
  geo_edit_targeo_instruction_3: 'Kliknij prawym przyciskiem myszy w miejscu poprawnych współrzędnych',
  geo_edit_targeo_instruction_4: 'Wybierz z menu "Linkuj do tego miejsca"',
  geo_edit_targeo_instruction_5: 'Skopiuj otrzymany link',
  geo_edit_targeo_instruction_6: 'Skopiowany link wklej w pole "Link do punktu na mapach targeo lub google"',
  geo_edit_targeo_instruction_7: 'Nowe współrzędne zostaną nadpisane tymi z linku',
}
